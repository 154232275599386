// bootstrap
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";

// Inter
import "@fontsource/ubuntu/300.css";
import "@fontsource/ubuntu/400.css";
import "@fontsource/ubuntu/500.css";
import "@fontsource/ubuntu/700.css";

// custom SCSS styles
import "./src/assets/styles/main.scss";

// onInitialClientRender

export const onInitialClientRender = () => {
    window.scrollTo(0, 0);
};
